import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Input, Alert, Button, ComboBox, Form, Row, Col, ButtonSwitch } from '@intech/web-ui';
import { Session } from "@intech/react-service";

import { Header, LoadingComponent } from '@components';
import { AdesaoService } from '@services';

import { FaArrowLeft, FaArrowRight, FaCheck, FaTimes } from 'react-icons/fa';
import { PatrocinadoraEntidade, DadosAdesaoEntidade, AdesaoEntidade } from '@entidades';
import { Titulo } from './styles';
import { Passos } from '../Handler';

interface IProps {
  dados: DadosAdesaoEntidade;
  setDados: (dados: any) => void;
  Avancar: (PassoEspecifico?: Passos | null) => void;
}

export const Passo1: React.FC<IProps> = ({
  dados,
  setDados,
  Avancar
}) => {

  const form = useRef<Form>(null);

  const [Nome, setNome] = useState<string>("");
  const [Cpf, setCpf] = useState<string>("");
  const [Matricula, setMatricula] = useState<string>("");
  const [ListaPatrocinadora, setListaPatrocinadora] = useState<Array<PatrocinadoraEntidade>>(new Array<PatrocinadoraEntidade>());
  const [Patrocinadora, setPatrocinadora] = useState<number>(0);
  const [DSPatrocinadora, setDSPatrocinadora] = useState<string>("");
  const [AceitaTermos, setAceitaTermos] = useState<boolean>(false);
  const [erro, setErro] = useState(null);
  const [AdesaoExistente, setAdesaoExistente] = useState<AdesaoEntidade>(null);
  const [Loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await Session.clear();

        const ListaPatrocinadora = await AdesaoService.BuscarListaPatrocinadora();
        setListaPatrocinadora(ListaPatrocinadora);

        setCpf(dados.CPF_CGC || "");
        setMatricula(dados.NUM_MATRICULA || "");
        setPatrocinadora(dados.CD_PESSOA_PATR || 0);
        setDSPatrocinadora(dados.DS_PATROCINADORA || "");
        setNome(dados.NO_PESSOA || "");

        setLoading(false);
      }
      catch (err) {
        const message = err.response ? err.response.data : err;
        setErro(message);
        setLoading(false);
      }
    })();
  }, []);

  function SalvarDados(DataInscricao: Date | null, Situacao: string | null) {
    let Dados = dados;
    Dados.NO_PESSOA = Nome;
    Dados.CPF_CGC = Cpf;
    Dados.NUM_MATRICULA = Matricula;
    Dados.CD_PESSOA_PATR = Patrocinadora;
    Dados.DS_PATROCINADORA = DSPatrocinadora;
    Dados.DT_INSCRICAO = DataInscricao;
    Dados.DS_SIT_PLANO = Situacao;

    setDados(Dados);
  }

  function PatrocinadoraOnChange(e: any) {
    const Patrocinadora = ListaPatrocinadora.filter(x => x.CD_PESSOA_PATR === e)[0];

    if (Patrocinadora === undefined) {
      setPatrocinadora(0);
      setDSPatrocinadora("");
      return;
    }

    setPatrocinadora(Patrocinadora.CD_PESSOA_PATR);
    setDSPatrocinadora(Patrocinadora.NO_PESSOA);
  }

  function Cancelar() {
    setCpf("");
    setMatricula("");
    setPatrocinadora(0);
    setDSPatrocinadora("");
    setNome("");
    setAdesaoExistente(null);
    setAceitaTermos(false);
  }

  async function onSubmit() {
    setErro("");

    try {
      const erros = await form?.current?.validate();

      if (erros) {
        setErro(erros);
        return;
      }

      await AdesaoService.ValidarCPF(Cpf);

      const cebprev = await AdesaoService.BuscarPlano(Cpf, 3);

      let proximo_passo = Passos.Passo2;

      let DataInscricao: Date = null;

      let Situacao: string = null;

      if (cebprev) {
        DataInscricao = cebprev.DT_INSC_PLANO;
        Situacao = cebprev.DS_SIT_PLANO;
        proximo_passo = Passos.Cadastrado;
      }

      const regex_dv = /\D/;

      if ((regex_dv.test(Matricula))) {
        setErro("O campo 'Matrícula Funcional' só aceita números.");
        return null
      }

      SalvarDados(DataInscricao, Situacao);

      const AdesaoExistente = await AdesaoService.BuscarAdesao(Cpf);

      if (AdesaoExistente) {
        setAdesaoExistente(AdesaoExistente);
        return;
      }

      Avancar(proximo_passo);
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  return (
    <Header width={"600px"}>
      {Loading && <LoadingComponent />}

      {!Loading && AdesaoExistente === null && <>
        <Alert type={"info"}>
          {"Podem solicitar inscrição no plano de previdência CEBPREV os empregados das patrocinadoras, dirigentes, ocupantes de Emprego em Comissão e os requisitados."}
        </Alert>

        <Titulo>
          {"Para começar, precisamos da sua identificação funcional:"}
        </Titulo>

        <Form ref={form}>
          <Input
            value={Nome}
            title={"Nome Completo"}
            placeholder={"Nome Completo"}
            onChange={setNome}
            labelSize={4}
            max={140}
            required
          />

          <Input
            value={Cpf}
            title={"CPF"}
            placeholder={"CPF"}
            onChange={setCpf}
            mask={"cpf"}
            labelSize={4}
            required
          />

          <ComboBox
            value={Patrocinadora}
            title={"Patrocinadora"}
            data={ListaPatrocinadora}
            onChange={PatrocinadoraOnChange}
            memberName={"NO_PESSOA"}
            memberValue={"CD_PESSOA_PATR"}
            emptyText={"Escolha sua Patrocinadora"}
            labelSize={4}
            required
          />

          <Input
            value={Matricula}
            title={"Matrícula Funcional"}
            placeholder={"Matrícula Funcional"}
            onChange={setMatricula}
            labelSize={4}
            max={10}
            required
          />

          <Alert type={"warning"}>
            <Row>
              <Col>
                <b>
                  {"TERMO DE CONSENTIMENTO E AUTORIZAÇÃO LIVRE, ESCLARECIDO E INEQUÍVOCO DE UTILIZAÇÃO DE DADOS PESSOAIS"}
                </b>
                <br />
                {"Em observância à Lei Geral de Proteção de Dados Pessoais - LGPD (Lei nº 13.709/2018), autorizo a Faceb a realizar o tratamento dos meus dados pessoais, fornecidos desde minha adesão ao(s) Plano(s) sob administração dessa Entidade. Tais informações são utilizadas somente para os fins lícitos e previstos na consecução do(s) Plano(s), em que sou participante ou assistido, nas avaliações atuariais e financeiras e, ainda, eventualmente compartilhadas com os órgãos governamentais que legalmente os requererem. Autorizo ainda o recebimento de e-mails, ligações, mensagens de WhatsApp e outros contatos de caráter estritamente institucional."}
              </Col>
            </Row>
            <br />
            <Row>
              <Col className={"col-lg-1"}>
                <ButtonSwitch
                  activeType={"success"}
                  inactiveType={"light"}
                  inactiveIcon={" "}
                  checked={AceitaTermos}
                  onChange={() => setAceitaTermos(!AceitaTermos)}
                />
              </Col>
              <Col>
                {"Li e concordo com o termo acima"}
              </Col>
            </Row>
          </Alert>

          <Row>
            <Col>
            </Col>

            <Col>
              <Button
                className={"w-100"}
                title={"Continuar"}
                type={"primary"}
                loadingText={"Carregando..."}
                icon={<FaArrowRight />}
                iconRight
                usesLoading
                submit
                onClick={onSubmit}
                disabled={!AceitaTermos}
              />
            </Col>
          </Row>

          <br />

          <Alert type={"danger"}>{erro}</Alert>
        </Form>
      </>}

      {!Loading && AdesaoExistente !== null && <>
        <Alert type={"info"}>
          {`Já existe uma Proposta de Inscrição solicitado por você no CPF ${Cpf} na data de ${moment(AdesaoExistente.DTA_CRIACAO, "DD/MM/YYYY").format("DD/MM/YYYY")}. O mesmo encontra - se em análise pela Faceb e em breve você será informado. Caso a Proposta de Inscrição informada anteriormente esteja incorreta, você pode solicitar uma nova. Deseja solicitar uma nova Proposta de Inscrição?`}
        </Alert>

        <Row>
          <Col>
            <Button
              className={"w-100 float-right"}
              title={"Não"}
              type={"secondary"}
              loadingText={"Carregando..."}
              icon={<FaTimes />}
              usesLoading
              block
              onClick={Cancelar}
            />
          </Col>

          <Col>
            <Button
              className={"w-100"}
              title={"Sim"}
              type={"primary"}
              loadingText={"Carregando..."}
              icon={<FaCheck />}
              iconRight
              usesLoading
              block
              onClick={Avancar}
            />
          </Col>
        </Row>
      </>}
    </Header>
  );
}
import React from 'react';
import { Input, Box, Label, Col, Row } from '@intech/web-ui';

import { DadosAdesaoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
}

export const DadosBancarios: React.FC<IProps> = ({
  dados
}) => {

  return (
    <Box title={"Dados Bancários"}>
      <Input
        value={dados.NO_BANCO}
        title={"Banco"}
        labelSize={6}
        readonly
      />

      <Input
        labelSize={6}
        title={"Agência"}
        value={dados.CD_AGENCIA}
        readonly
      />

      <div className="input-group">
        <Label
          value={"N° da Conta + DV"}
          size={6}
        />
        <Input
          value={dados.NR_CC}
          readonly
        />
        <Input
          value={dados.DV_CC}
          readonly
        />
      </div>
    </Box>
  );
}
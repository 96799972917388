import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { Input, Form, Box, ComboBox, Alert, Row, Label, Col } from '@intech/web-ui';

import { MunicipioEntidade, UFEntidade } from '@entidades';

interface IProps {
  CEP: string;
  setCEP: (d: string) => void;
  Endereco: string;
  setEndereco: (d: string) => void;
  Numero: string;
  setNumero: (d: string) => void;
  Complemento: string;
  setComplemento: (d: string) => void;
  Bairro: string;
  setBairro: (d: string) => void;
  Telefone: string;
  setTelefone: (d: string) => void;
  Celular: string;
  setCelular: (d: string) => void;
  Email: string;
  setEmail: (d: string) => void;
  Municipio: string;
  MunicipioOnChange: (e: any) => void;
  ListaMunicipio: Array<MunicipioEntidade>;
  UF: string;
  UFOnChange: (e: any) => void;
  ListaUF: Array<UFEntidade>;
  form: React.MutableRefObject<Form>;
}

export const DadosEndereco: React.FC<IProps> = ({
  CEP,
  setCEP,
  Endereco,
  setEndereco,
  Numero,
  setNumero,
  Complemento,
  setComplemento,
  Bairro,
  setBairro,
  Telefone,
  setTelefone,
  Celular,
  setCelular,
  Email,
  setEmail,
  Municipio,
  MunicipioOnChange,
  ListaMunicipio,
  UF,
  UFOnChange,
  ListaUF,
  form
}) => {
  const [AlertText, setAlertText] = useState<string>("");

  async function ConsultarCep() {
    try {
      setAlertText("");
      const cepres = (await axios.get(`https://viacep.com.br/ws/${CEP}/json/`)).data;

      setEndereco(cepres.logradouro);
      setComplemento(cepres.complemento);
      setBairro(cepres.bairro);
      // setCidade(cepres.localidade);
      UFOnChange(cepres.uf);
    }
    catch (e) {
      setAlertText(e.response ? e.response.data : e);
    }
  }

  return (
    <Box title={"Endereço"}>
      <Form ref={form}>

        <Input
          title={"CEP"}
          value={CEP}
          mask={"cep"}
          labelSize={4}
          onChange={setCEP}
          required
          group
          buttonTitle={"Consultar"}
          buttonType={"primary"}
          onButtonClick={ConsultarCep}
        />

        <Input
          title={"Logradouro"}
          value={Endereco}
          labelSize={4}
          onChange={setEndereco}
          max={120}
          required
        />

        <Input
          title={"Número"}
          value={Numero}
          labelSize={4}
          onChange={setNumero}
          max={20}
          required
        />

        <Input
          title={"Complemento"}
          value={Complemento}
          labelSize={4}
          onChange={setComplemento}
          max={40}
        />

        <Input
          title={"Bairro"}
          value={Bairro}
          labelSize={4}
          onChange={setBairro}
          max={40}
          required
        />

        <ComboBox
          value={UF}
          title={"UF"}
          data={ListaUF}
          onChange={UFOnChange}
          memberName={"DS_UF"}
          memberValue={"CD_UF"}
          emptyText={"Escolha sua UF"}
          labelSize={4}
          required
        />

        {ListaMunicipio.length === 0 &&
          <Alert type={"info"}>
            {"Escolha a sua UF para selecionar a Cidade"}
          </Alert>
        }

        {ListaMunicipio.length > 0 &&
          <ComboBox
            value={Municipio}
            title={"Cidade"}
            data={ListaMunicipio}
            onChange={MunicipioOnChange}
            memberName={"DS_MUNICIPIO"}
            memberValue={"CD_MUNICIPIO"}
            emptyText={"Escolha sua Cidade"}
            labelSize={4}
            required
          />}

        <div className="form-group" >
          <Row>
            <Label
              value={"Telefone Fixo"}
              size={4}
            />

            <Col>
              <div>
                <NumberFormat
                  className={"form-control"}
                  format={"(##)####-####"}
                  mask={"_"}
                  value={Telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="form-group" >
          <Row>
            <Label
              value={"Celular"}
              size={4}
              required
            />

            <Col>
              <div>
                <NumberFormat
                  className={"form-control"}
                  format={"(##)#####-####"}
                  mask={"_"}
                  value={Celular}
                  onChange={(e) => setCelular(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Input
          title={"E-mail"}
          value={Email}
          labelSize={4}
          onChange={setEmail}
          max={400}
          required
        />

        <Alert type={"danger"}>
          {AlertText}
        </Alert>

      </Form>
    </Box>
  );
}
import React from 'react';
import moment from 'moment';
import { DependenteEntidade } from '@entidades';

import { LoadingComponent } from '@components';
import { Alert, Box, Button } from '@intech/web-ui';
interface IProps {
  ListaDependentes: Array<DependenteEntidade>;
  editar: (dep: DependenteEntidade) => void;
  remover: (dep: DependenteEntidade) => void;
  title?: string;
}

export const ListaDependente: React.FC<IProps> = ({
  ListaDependentes,
  editar,
  remover,
  title = "Dependentes"
}) => {

  function renderDependentes() {

    if (ListaDependentes !== undefined) {
      if (ListaDependentes.length > 0) {
        const Itens = ListaDependentes.map((dep: DependenteEntidade, index: number) => {
          const cor = index % 2 === 0
            ? "table-active "
            : "";
          return (
            <tr key={index} className={cor + "text-center"}>
              {editar !== null &&
                <td>
                  <Button
                    title={"Editar"}
                    size={"mini"}
                    type={"primary"}
                    onClick={() => editar(dep)}
                  />
                </td>
              }
              {remover !== null &&
                <td>
                  <Button
                    title={"Remover"}
                    size={"mini"}
                    type={"danger"}
                    onClick={() => remover(dep)}
                  />
                </td>
              }
              <td>
                {dep.DS_GRAU_PARENTESCO}
              </td>
              <td>
                {dep.NO_PESSOA}
              </td>
              <td>
                {moment(dep.DT_NASCIMENTO, "DD/MM/YYYY").format("DD/MM/YYYY")}
              </td>
              <td>
                {dep.IR_SEXO == "F" ? "FEMININO" : "MASCULINO"}
              </td>
              <td>
                {dep.NR_CPF}
              </td>
            </tr>
          );
        });
        return (
          <Box title={`${title} Incluídos`}>
            <div className={"table-responsive"}>
              <table className={"table table-striped table-sm"}>
                <thead>
                  <tr className={"text-center"}>
                    {editar !== null && <th></th>}
                    {remover !== null && <th></th>}
                    <th style={{ "minWidth": "150px" }}>Grau de Parentesco</th>
                    <th style={{ "minWidth": "250px" }}>Nome</th>
                    <th style={{ "minWidth": "150px" }}>Data de Nascimento</th>
                    <th>Sexo</th>
                    <th style={{ "minWidth": "150px" }}>CPF</th>
                  </tr>
                </thead>

                <tbody>
                  {Itens}
                </tbody>
              </table>
            </div>
          </Box>
        );
      }

      if (ListaDependentes.length === 0) {
        return <Alert type="info">
          {"Nehum Beneficiario cadastrado."}
        </Alert>;
      }
    }

    return <LoadingComponent />;
  }

  return renderDependentes();
}
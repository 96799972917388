import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Row } from '@intech/web-ui';

import { Header, LoadingComponent } from '@components';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { DadosAdesaoEntidade } from '@entidades';
import { Passos } from '../Handler';
import { ContribuicaoBasica } from './ContribuicaoBasica';
import { RegimeTributacao } from './RegimeTributacao';
import { PessoaPoliticamenteExposta } from './PessoaPoliticamenteExposta';
import { USPerson } from './USPerson';

interface IProps {
  dados: DadosAdesaoEntidade;
  setDados: (dados: any) => void;
  Avancar: (PassoEspecifico?: Passos | null) => void;
  Voltar: () => void;
}

export const Passo3: React.FC<IProps> = ({
  dados,
  setDados,
  Avancar,
  Voltar
}) => {

  const form = useRef<Form>(null);

  const [PercentualContribuicaoBasica, setPercentualContribuicaoBasica] = useState<string>("");
  // const [Regime, setRegime] = useState<string>("");
  // const [AceitaTermos, setAceitaTermos] = useState<boolean>(false);
  const [PPE, setPPE] = useState<string>("");
  const [FPE, setFPE] = useState<string>("");
  const [USP, setUSP] = useState<string>("");

  const [erro, setErro] = useState(null);
  const [Loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        setPercentualContribuicaoBasica(dados.CONTRIB_BASICA);
        // setRegime(dados.IRRF_OPCAO_TRIBUTACAO);
        setPPE(dados.PPE);
        setFPE(dados.FPE);
        setUSP(dados.USP);

        setLoading(false);
      }
      catch (err) {
        const message = err.response ? err.response.data : err;
        setErro(message);
        setLoading(false);
      }
    })();
  }, []);

  function SalvarDados() {
    let Dados = dados;

    Dados.CONTRIB_BASICA = PercentualContribuicaoBasica;
    // Dados.IRRF_OPCAO_TRIBUTACAO = Regime;
    Dados.IRRF_OPCAO_TRIBUTACAO = "1";
    Dados.PPE = PPE;
    Dados.FPE = FPE;
    Dados.USP = USP;

    setDados(Dados);
  }

  function CondicaoAvancar() {
    return (
      PercentualContribuicaoBasica &&
      // Regime &&
      PPE &&
      FPE &&
      USP
    );
  }

  function VoltarPasso() {
    SalvarDados();
    Voltar();
  }

  async function onSubmit() {
    setErro("");

    try {
      const erros = await form?.current?.validate();

      if (erros) {
        setErro(erros);
        return;
      }

      if (!CondicaoAvancar()) {
        setErro("Todos os campos são obrigatórios.");
        return;
      }

      SalvarDados();

      Avancar();
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  return (
    <Header width={"700px"}>
      {Loading && <LoadingComponent />}

      {!Loading && <>

        <b>{"Mais algumas informações importantes..."}</b>
        <br />
        <small>* Obrigatórios</small>

        <br />
        <br />

        <Form ref={form}>

          <ContribuicaoBasica
            PercentualContribuicaoBasica={PercentualContribuicaoBasica}
            setPercentualContribuicaoBasica={setPercentualContribuicaoBasica}
          />

          {/* <RegimeTributacao
            Regime={Regime}
            setRegime={setRegime}
            AceitaTermos={AceitaTermos}
            setAceitaTermos={setAceitaTermos}
          /> */}

          <PessoaPoliticamenteExposta
            PPE={PPE}
            setPPE={setPPE}
            FPE={FPE}
            setFPE={setFPE}
          />

          <USPerson
            USP={USP}
            setUSP={setUSP}
          />

          <Row>
            <Col>
              <Button
                className={"w-100 float-right"}
                title={"Voltar"}
                type={"secondary"}
                loadingText={"Carregando..."}
                icon={<FaArrowLeft />}
                usesLoading
                block
                submit
                onClick={VoltarPasso}
              />
            </Col>

            <Col>
              <Button
                className={"w-100"}
                title={"Continuar"}
                type={"primary"}
                loadingText={"Carregando..."}
                icon={<FaArrowRight />}
                iconRight
                usesLoading
                block
                submit
                onClick={onSubmit}
              // disabled={!AceitaTermos}
              />
            </Col>
          </Row>

          <br />

          <Alert type={"danger"}>{erro}</Alert>
        </Form>
      </>}
    </Header>
  );
}
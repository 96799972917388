import React, { useEffect, useState } from 'react';
import { Passo1, Passo2, Passo3, Passo4, Passo5 } from '..';
import { DadosAdesaoEntidade } from '@entidades';
import { Cadastrado } from '../Cadastrado';
import { Resumo } from '../Resumo';

export enum Passos {
  Cadastrado = "Cadastrado",
  Passo1 = "Passo1",
  Passo2 = "Passo2",
  Passo3 = "Passo3",
  Passo4 = "Passo4",
  Passo5 = "Passo5",
  Resumo = "Resumo",
}

export const Handler: React.FC = () => {
  const [Dados, setDados] = useState<DadosAdesaoEntidade>(new DadosAdesaoEntidade());
  const [PassoAtivo, setPassoAtivo] = useState<Passos>(Passos.Passo1);

  useEffect(() => {
    (async () => {
      try {
        //...
      }
      catch (err) {
        console.log(err);
      }
    })();
  }, []);

  function Avancar(PassoEspecifico: Passos | null = null) {

    const passos = Object.values(Passos);

    const atual = passos.indexOf(PassoEspecifico || PassoAtivo);

    if (PassoEspecifico !== null) {
      setPassoAtivo(passos[atual]);
      return;
    }

    const proximo = atual + 1;
    setPassoAtivo(passos[proximo]);
  }

  function Voltar(PassoEspecifico: Passos | null = null) {

    const passos = Object.values(Passos);

    const atual = passos.indexOf(PassoEspecifico || PassoAtivo);

    if (PassoEspecifico !== null) {
      setPassoAtivo(passos[atual]);
      return;
    }
    const anterior = atual - 1;
    setPassoAtivo(passos[anterior]);
  }

  function renderPassoAtivo() {
    const p = {
      "Cadastrado":
        <Cadastrado
          dados={Dados}
          Voltar={Voltar}
        />,
      "Passo1":
        <Passo1
          dados={Dados}
          setDados={setDados}
          Avancar={Avancar}
        />,
      "Passo2":
        <Passo2
          dados={Dados}
          setDados={setDados}
          Avancar={Avancar}
          Voltar={Voltar}
        />,
      "Passo3":
        <Passo3
          dados={Dados}
          setDados={setDados}
          Avancar={Avancar}
          Voltar={Voltar}
        />,
      "Passo4":
        <Passo4
          dados={Dados}
          setDados={setDados}
          Avancar={Avancar}
          Voltar={Voltar}
        />,
      "Passo5":
        <Passo5
          dados={Dados}
          setDados={setDados}
          Avancar={Avancar}
          Voltar={Voltar}
        />,
      "Resumo":
        <Resumo
          dados={Dados}
          Voltar={Voltar}
        />

    }
    return p[PassoAtivo];
  }

  return renderPassoAtivo();
}
import React from 'react';
import { Alert, Box, ComboBox } from '@intech/web-ui';
import { ComboContribuicaoBasica } from './ComboContribuicaoBasica';

interface IProps {
  PercentualContribuicaoBasica: string;
  setPercentualContribuicaoBasica: (e: any) => void;
}

export const ContribuicaoBasica: React.FC<IProps> = ({
  PercentualContribuicaoBasica,
  setPercentualContribuicaoBasica
}) => {

  return (
    <Box title={"Contribuição Básica"}>
      <ComboBox
        value={PercentualContribuicaoBasica}
        title={"Percentual de Contribuição Básica"}
        data={ComboContribuicaoBasica}
        onChange={setPercentualContribuicaoBasica}
        memberName={"Name"}
        memberValue={"Value"}
        emptyText={"Selecione um Percentual"}
        labelSize={6}
        required
      />

      <Alert type={"info"}>
        {"A contribuição básica será escolhida pelo participante entre os percentuais de 5% a 10%, sobre o salário de participação, com contribuição paritária da patrocinadora no mesmo percentual, para custeio dos benefícios programáveis."}
      </Alert>
    </Box>
  );
}
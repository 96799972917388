import React from 'react';
import { Box } from '@intech/web-ui';

export const USPersonModalConteudo: React.FC = () => {

  return (
    <>
      <Box title={"US PERSON"}>
        <p>
          {"Conforme acordo (FATCA) firmado entre o Governo Brasileiro e o Governo Americano, com o objetivo de combater a evasão fiscal dos Estados Unidos, a FACEB, como Entidade Fechada de Previdência Complementar, deve identificar todos os participantes denominados US PERSON em sua base de dados, ou seja, pessoas que possuem uma das características:"}
        </p>
        <ul>
          <li>{"Ter cidadania norte-americana;"}</li>
          <li>{"Ter dupla nacionalidade, uma delas norte-americana;"}</li>
          <li>{"Ter passaporte norte - americano, mesmo com residência fora dos Estados Unidos;"}</li>
          <li>{"Ser detentor de Green Card;"}</li>
          <li>{"Ter local de nascimento nos Estados Unidos;"}</li>
          <li>{"Possuir telefone nos Estados Unidos;"}</li>
          <li>{"Ter residência permanente ou presença substancial nos Estados Unidos (se ficou nos Estados Unidos pelo menos 31 dias no ano corrente e / ou 183 dias nos últimos três anos);"}</li>
          <li>{"Possuir, pelo menos, 10% de participação em empresas nacionais ou internacionais;"}</li>
        </ul>
      </Box>
    </>
  );
}
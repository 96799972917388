import { Box } from '@intech/web-ui';
import React from 'react';
import { Container, Loader } from './styles';

export const LoadingComponent: React.FC = () => {
  return (
    <Box>
      <Container>
        <Loader src={"./imagens/loading.gif"} alt={"loader"} />

        <div>Carregando...</div>
      </Container>
    </Box>
  );
}

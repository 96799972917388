import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Alert, Box, Button, ButtonSwitch, Col, ComboBox, Form, Input, Label, Row } from '@intech/web-ui';

import { Header, ListaDependente, LoadingComponent } from '@components';

import { FaArrowLeft, FaArrowRight, FaCheck, FaEdit, FaTimes } from 'react-icons/fa';
import { DadosAdesaoEntidade, DependenteEntidade, GrauParentescoEntidade, SexoEntidade } from '@entidades';
import { Passos } from '../Handler';
import { AdesaoService } from '@services';

interface IProps {
  dados: DadosAdesaoEntidade;
  setDados: (dados: any) => void;
  Avancar: (PassoEspecifico?: Passos | null) => void;
  Voltar: () => void;
}

export const Passo4: React.FC<IProps> = ({
  dados,
  setDados,
  Avancar,
  Voltar
}) => {

  const form = useRef<Form>(null);

  const [GrauParentesco, setGrauParentesco] = useState<string>("");
  // const [DSGrauParentesco, setDSGrauParentesco] = useState<string>("");
  const [Nome, setNome] = useState<string>("");
  const [DataNascimento, setDataNascimento] = useState<string>("");
  const [Sexo, setSexo] = useState<string>("");
  const [Cpf, setCpf] = useState<string>("");
  const [TipoDependente, setTipoDependente] = useState<string>("NAO");
  const [ListaDependentes, setListaDependentes] = useState<Array<DependenteEntidade>>(new Array<DependenteEntidade>());
  const [ListaGrauParentesco, setListaGrauParentesco] = useState<Array<GrauParentescoEntidade>>(new Array<GrauParentescoEntidade>());
  const [ListaSexo, setListaSexo] = useState<Array<SexoEntidade>>(new Array<SexoEntidade>());

  const [Acao, setAcao] = useState<"Incluir" | "Editar">("Incluir");
  const [erro, setErro] = useState(null);
  const [Loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const ListaGrauParentesco = await AdesaoService.BuscarListaGrauParentesco();
        setListaGrauParentesco(ListaGrauParentesco);

        const ListaSexo = await AdesaoService.BuscarListaSexo();
        setListaSexo(ListaSexo);

        setListaDependentes(dados.ListaDependentes || new Array<DependenteEntidade>());

        if (dados.ListaDependentes && dados.ListaDependentes.length > 0) {
          setTipoDependente(dados.ListaDependentes[0].IND_DESIGNADO);
        }

        setLoading(false);
      }
      catch (err) {
        const message = err.response ? err.response.data : err;
        setErro(message);
        setLoading(false);
      }
    })();
  }, []);

  function SalvarDados() {
    let Dados = dados;

    Dados.ListaDependentes = ListaDependentes;

    setDados(Dados);
  }

  function VoltarPasso() {
    SalvarDados();
    Voltar();
  }

  async function onSubmit() {
    setErro("");

    try {
      // if (ListaDependentes.length === 0) {
      //   setErro("A inclusão de pelo menos 1 (um) Dependente é obrigatório.");
      //   return;
      // }

      SalvarDados();

      Avancar();
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  async function incluir(dep: DependenteEntidade) {
    setErro("");

    try {
      const erros = await form?.current?.validate();

      if (erros) {
        setErro(erros);
        return;
      }

      await AdesaoService.ValidarCPF(Cpf);

      const CPFRepetido = ListaDependentes.find((x) => x.NR_CPF === Cpf);

      // const CPFRepetido = new Set(CPFs).size !== CPFs.length;

      if (CPFRepetido !== undefined) {
        setErro("Os CPFs dos beneficiários não podem ser iguais.");
        return;
      }

      const jaTemConjuge = ListaDependentes.find((dep) => dep.CD_GRAU_PARENTESCO === 1 || dep.CD_GRAU_PARENTESCO === 100);

      if (jaTemConjuge !== undefined && (GrauParentesco === "1" || GrauParentesco === "100")) {
        setErro('Somente é permitido cadastrar 1 (um(a)) "ESPOSO(A)" e "ESPOSO(A) - ESPECIAL".');
        return;
      }

      const GParentesco = ListaGrauParentesco.filter(x => x.CD_GRAU_PARENTESCO === parseInt(GrauParentesco))[0];

      let novoDep = new DependenteEntidade();

      novoDep.NO_PESSOA = Nome;
      novoDep.CD_GRAU_PARENTESCO = GParentesco.CD_GRAU_PARENTESCO;
      novoDep.DS_GRAU_PARENTESCO = GParentesco.DS_GRAU_PARENTESCO;
      novoDep.DT_NASCIMENTO = moment(DataNascimento, "DD/MM/YYYY").toDate();
      novoDep.IR_SEXO = Sexo;
      novoDep.NR_CPF = Cpf;
      novoDep.IND_DESIGNADO = TipoDependente;

      setListaDependentes([...ListaDependentes, novoDep]);

      setNome("");
      setGrauParentesco("");
      setDataNascimento("");
      setSexo("");
      setCpf("");
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  function prepararEditar(dep: DependenteEntidade) {
    try {
      setAcao("Editar");

      setNome(dep.NO_PESSOA);
      setGrauParentesco(dep.CD_GRAU_PARENTESCO.toString());
      setDataNascimento(moment(dep.DT_NASCIMENTO, "DD/MM/YYYY").format("DD/MM/YYYY"));
      setSexo(dep.IR_SEXO);
      setCpf(dep.NR_CPF);
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  async function editar(dep: DependenteEntidade) {
    setErro("");

    try {
      const erros = await form?.current?.validate();

      if (erros) {
        setErro(erros);
        return;
      }

      await AdesaoService.ValidarCPF(Cpf);

      const CPFRepetido = ListaDependentes.filter((x) => x.NR_CPF === Cpf);

      // const CPFRepetido = new Set(CPFs).size !== CPFs.length;

      if (CPFRepetido.length > 1) {
        setErro("Os CPFs dos beneficiários não podem ser iguais.");
        return;
      }

      const jaTemConjuge = ListaDependentes.filter((dep) => dep.CD_GRAU_PARENTESCO === 1 || dep.CD_GRAU_PARENTESCO === 100);

      if (jaTemConjuge.length > 1) {
        setErro('Somente é permitido cadastrar 1 (um(a)) "ESPOSO(A)" e "ESPOSO(A) - ESPECIAL".');
        return;
      }

      const GParentesco = ListaGrauParentesco.filter(x => x.CD_GRAU_PARENTESCO === parseInt(GrauParentesco))[0];

      let novoDep = new DependenteEntidade();

      novoDep.NO_PESSOA = Nome;
      novoDep.CD_GRAU_PARENTESCO = GParentesco.CD_GRAU_PARENTESCO;
      novoDep.DS_GRAU_PARENTESCO = GParentesco.DS_GRAU_PARENTESCO;
      novoDep.DT_NASCIMENTO = moment(DataNascimento, "DD/MM/YYYY").toDate();
      novoDep.IR_SEXO = Sexo;
      novoDep.NR_CPF = Cpf;
      novoDep.IND_DESIGNADO = TipoDependente;

      const l = ListaDependentes.filter((item) => item.NR_CPF !== novoDep.NR_CPF);

      setListaDependentes([...l, novoDep]);

      setAcao("Incluir");

      setNome("");
      setGrauParentesco("");
      setDataNascimento("");
      setSexo("");
      setCpf("");
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  function remover(dep: DependenteEntidade) {
    if (
      window.confirm(
        "Deseja realmente remover o beneficiário selecionado?"
      )
    ) {
      const l = ListaDependentes.filter((item) => item !== dep);
      setListaDependentes(l);
    }
  }

  return (
    <Header width={"700px"}>
      {Loading && <LoadingComponent />}

      {!Loading && <>

        <b>{"Agora precisamos conhecer seus dependentes beneficiários"}</b>
        <br />
        <small>* Obrigatórios</small>

        <Alert type={"info"}>
          <b>
            {"Importante:"}
          </b>

          <p>
            {"Consideram-se Beneficiários os dependentes de Participante:"}
          </p>

          <p>
            {"I - O cônjuge ou companheiro(a) mantido(a) em união estável nos termos da legislação vigente;"}
            <br />
            {"II - Os filhos solteiros menores de 21 (vinte e um) anos, desde que não emancipados, e os inválidos de qualquer idade."}
          </p>

          <p>
            {"Na falta poderá designar outra pessoa para recebimento do saldo remanescente em sua conta do plano."}
          </p>

          <p>
            {"Observe que só poderá ser cadastrado dependente designado se não houver dependente beneficiário normal."}
          </p>

          <p>
            {"Observe também que não é obrigatório a inclusão de Dependentes."}
          </p>
        </Alert>

        <Box title={`${Acao === "Editar" ? "Edição" : "Inclusão"} de Dependentes`}>

          <Form ref={form}>
            {ListaDependentes.length === 0 &&
              <div className={"form-group col-md"}>
                <Label
                  value={"Tipo do Dependente"}
                  size={4}
                />

                <ButtonSwitch
                  activeType={"success"}
                  activeTitle={"Beneficiário"}
                  activeIcon={null}

                  inactiveType={"success"}
                  inactiveTitle={"Designado"}
                  inactiveIcon={null}

                  checked={TipoDependente === "NAO"}
                  onChange={() => setTipoDependente(TipoDependente === "SIM" ? "NAO" : "SIM")}
                />
              </div>
            }

            <ComboBox
              value={GrauParentesco}
              title={"Grau de Parentesco"}
              data={ListaGrauParentesco}
              onChange={setGrauParentesco}
              memberName={"DS_GRAU_PARENTESCO"}
              memberValue={"CD_GRAU_PARENTESCO"}
              emptyText={"Selecione um Grau de Parentesco"}
              labelSize={4}
              required
            />

            <Input
              labelSize={4}
              title={"Nome do Dependente"}
              value={Nome}
              onChange={setNome}
              max={100}
              required
            />

            <Input
              labelSize={4}
              title={"Data de Nascimento"}
              value={DataNascimento}
              onChange={setDataNascimento}
              type={"date"}
              required
            />

            <ComboBox
              value={Sexo}
              title={"Sexo"}
              data={ListaSexo}
              onChange={setSexo}
              memberName={"DS_SEXO"}
              memberValue={"IR_SEXO"}
              emptyText={"Escolha o Sexo"}
              labelSize={4}
              required
            />

            <Input
              value={Cpf}
              title={"CPF"}
              placeholder={"CPF"}
              onChange={setCpf}
              mask={"cpf"}
              labelSize={4}
              required
            />

            {ListaDependentes.length > 0 &&
              <Alert type={"info"}>
                {"Só poderá ser cadastrado dependente designado se não houver dependente beneficiário normal. Para alterar a designação, limpe a lista e escolha novamente."}
              </Alert>
            }

            {Acao === "Editar" &&
              <Button
                title={"Cancelar Edição"}
                type={"danger"}
                loadingText={"Cancelando..."}
                icon={<FaTimes />}
                usesLoading
                block
                submit
                onClick={() => setAcao("Incluir")}
              />
            }

            <Button
              title={`${Acao === "Incluir" ? Acao : "Salvar Edição"} ${TipoDependente === "SIM" ? "Designado" : "Beneficiário"}`}
              type={"primary"}
              loadingText={Acao === "Incluir" ? "Incluindo..." : "Editando..."}
              icon={Acao === "Incluir" ? <FaCheck /> : <FaEdit />}
              usesLoading
              block
              submit
              onClick={Acao === "Incluir" ? incluir : editar}
            />

            <br />

            <ListaDependente
              ListaDependentes={ListaDependentes}
              editar={prepararEditar}
              remover={remover}
              title={TipoDependente === "SIM" ? "Designados" : "Beneficiários"}
            />

            <br />

            <Alert type={"danger"}>{erro}</Alert>
          </Form>
        </Box>

        <Row>
          <Col>
            <Button
              className={"w-100 float-right"}
              title={"Voltar"}
              type={"secondary"}
              loadingText={"Carregando..."}
              icon={<FaArrowLeft />}
              usesLoading
              block
              onClick={VoltarPasso}
            />
          </Col>

          <Col>
            <Button
              className={"w-100"}
              title={"Continuar"}
              type={"primary"}
              loadingText={"Carregando..."}
              icon={<FaArrowRight />}
              iconRight
              usesLoading
              block
              onClick={onSubmit}
            //disabled={ListaDependentes.length === 0}
            />
          </Col>
        </Row>

      </>}
    </Header>
  );
}
import React from 'react';

import packageJson from '../../../package.json';
import { Logo, Panel, PanelTitulo } from './styles';

export const Header: React.FC<any> = ({ children, width }) => {
  return (
    <Panel className="middle-box" style={{ width: width }}>
      <Logo>
        <img width="160px" src="./imagens/logo.png" alt="Logo da Faceb" />
      </Logo>

      <PanelTitulo>Inscrição ao Plano CEBPREV</PanelTitulo>

      <br />

      {children}

      <br />
      <br />
      <div className="text-center">
        Versão {packageJson.version}
      </div>
    </Panel>
  )
}
import React from 'react';
import { Box, Button, ButtonSwitch, Col, Row, useModal } from '@intech/web-ui';

interface IProps {
  PPE: string;
  setPPE: (e: any) => void;
  FPE: string;
  setFPE: (e: any) => void;
}

export const PessoaPoliticamenteExposta: React.FC<IProps> = ({
  PPE,
  setPPE,
  FPE,
  setFPE
}) => {
  const modal = useModal();

  function OpenModal() {
    modal.openModal({
      title: "Você é uma pessoa politicamente exposta?",
      type: "message",
      content: "Enquadra-se na condição de pessoa politicamente exposta os agentes públicos que desempenham ou tenham desempenhado, nos últimos cinco anos, no Brasil ou em países, territórios ou dependências estrangeiras, cargos, empregos ou funções públicas relevantes, assim como seus familiares na linha direta e até o segundo grau, o cônjuge, o companheiro, a companheira, o enteado e a enteada, na forma do §1º, artigo 2º, da resolução COAF nº 29/2017",
    })
  };

  return (
    <Box title={"Pessoa Politicamente Exposta (IN PREVIC Nº 34/2020)"} badgeText={<Button title={"(Saiba Mais)"} type={"link"} onClick={OpenModal} />}>

      <Row className={"mb-1"}>
        <Col className={"text-right"}>
          <b>
            <span className={"text-danger"}>* </span>
            {"Considera-se enquadrado como PESSOA POLITICAMENTE EXPOSTA (IN PREVIC Nº 34/2020)"}
          </b>
        </Col>
      </Row>

      <Row className={"mb-1"}>
        <Col className={"d-flex justify-content-end"}>
          <ButtonSwitch
            activeType={"success"}
            inactiveType={"light"}
            inactiveIcon={" "}
            activeTitle={"SIM"}
            inactiveTitle={"SIM"}
            checked={PPE === "SIM"}
            onChange={() => setPPE("SIM")}
          />
        </Col>
        <Col>
          <ButtonSwitch
            activeType={"success"}
            inactiveType={"light"}
            inactiveIcon={" "}
            activeTitle={"NAO"}
            inactiveTitle={"NAO"}
            checked={PPE === "NAO"}
            onChange={() => setPPE("NAO")}
          />
        </Col>
      </Row>

      <Row className={"mb-1"}>
        <Col className={"text-right"}>
          <b>
            <span className={"text-danger"}>* </span>
            {"Há familiares (pais, filhos, cônjuge, companheiro(a) ou enteado(a) que possam estar enquadrados como PESSOA POLITICAMENTE EXPOSTA"}
          </b>
        </Col>
      </Row>

      <Row className={"mb-1"}>
        <Col className={"d-flex justify-content-end"}>
          <ButtonSwitch
            activeType={"success"}
            inactiveType={"light"}
            inactiveIcon={" "}
            activeTitle={"SIM"}
            inactiveTitle={"SIM"}
            checked={FPE === "SIM"}
            onChange={() => setFPE("SIM")}
          />
        </Col>
        <Col>
          <ButtonSwitch
            activeType={"success"}
            inactiveType={"light"}
            inactiveIcon={" "}
            activeTitle={"NAO"}
            inactiveTitle={"NAO"}
            checked={FPE === "NAO"}
            onChange={() => setFPE("NAO")}
          />
        </Col>
      </Row>

    </Box>
  );
}
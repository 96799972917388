import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Box, Button } from '@intech/web-ui';

import config from "./../../config.json";
import { ArquivoUploadBox } from './styles';
import { AdesaoService } from '@services';
import { FileUploadEntidade } from '@entidades';
import { FaUpload } from 'react-icons/fa';
import { LoadingComponent } from '../LoadingComponent';

interface IProps {
  PassoAtual: string;
  texto?: string;
  ListaArquivo: Array<ArquivoUploadItem>;
  mostrarAviso?: boolean;
  setListaArquivo: any;
}

export interface ArquivoUploadItem {
  Passo: string;
  Arquivo: File;
  Oid: string;
  Nome: string;
}

export interface ArquivoUploadBoxProps {
  type: "danger" | "warning" | "info" | "success"
}

export const ArquivoUpload: React.FC<IProps> = ({
  PassoAtual,
  texto,
  ListaArquivo,
  mostrarAviso = false,
  setListaArquivo
}) => {

  let fileUpload: any;

  const apiUrl = config.apiUrl;

  const [Loading, setLoading] = useState<boolean>(false);
  const [ListaLocal, setListaLocal] = useState<ArquivoUploadItem[]>(ListaArquivo.filter(a => a.Passo === PassoAtual));
  const [NomeArquivo, setNomeArquivo] = useState<string>("");
  // const [Cor, setCor] = useState<string>("");
  const [AlertText, setAlertText] = useState<string>("");

  // function MostrarAviso(DeveMostrar: boolean){
  //   const Cor = DeveMostrar ? "alert-danger" : ""
  //   setCor(Cor);
  // }

  async function uploadFile() {
    setLoading(true);
    try {
      setAlertText("");

      const file = fileUpload.files[0];

      const formData = new FormData()
      let arquivoUpload = file;

      formData.append("file", arquivoUpload, `${PassoAtual}, ${arquivoUpload.name}`)

      const app = `@${config.appName}:token`;
      const token = localStorage.getItem(app);

      // const arquivoOid = 1;

      const arquivoOid = (await axios({
        method: 'post',
        url: `${apiUrl}/Adesao/Upload`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
        data: formData
      })).data;

      if (arquivoOid !== 0) {
        const NovoItem: ArquivoUploadItem = {
          Arquivo: arquivoUpload,
          Passo: PassoAtual + "",
          Oid: arquivoOid,
          Nome: arquivoUpload.name
        };

        setListaArquivo([...ListaArquivo, NovoItem]);

        setListaLocal([...ListaLocal, NovoItem]);
      }

      setNomeArquivo("");
    }
    catch (err) {
      const e = err.response ? err.response.data : err;
      console.error(e);
      setAlertText(e);
    }
    setLoading(false);
  }

  function arquivosEnviados() {
    const ListaArquivoPlano = ListaLocal.filter(arquivo => arquivo.Passo = PassoAtual);
    if (ListaArquivoPlano.length > 0) {
      const nomes = ListaArquivoPlano.map((arquivo: ArquivoUploadItem, index: number) => {
        return (
          <li key={index} className={"list-group-item"}>
            {arquivo.Arquivo.name}
            <Button
              title={"Cancelar envio"}
              className={"m-1"}
              size={"mini"}
              type={"danger"}
              onClick={() => cancelarEnvio(arquivo)}
            />
          </li>
        );
      });
      return (
        <ul className={"list-group text-primary"}>{"Arquivos carregados:"}{nomes}</ul>
      );
    }
    return null;
  }

  function cancelarEnvio(arquivo: ArquivoUploadItem) {
    const l = ListaArquivo.filter(item => item !== arquivo);
    const listaLocal = ListaLocal.filter(item => item !== arquivo);
    setListaArquivo(l);
    setListaLocal(listaLocal);
    // setNomeArquivo("");
  }

  function clickarInputFile() {
    const input = document.getElementById("selecionar-documento");
    console.log(input);
    input.click();
  }

  return (
    <ArquivoUploadBox className={mostrarAviso ? "alert-danger" : "alert-warning"}>
      {/* <span><b>{"Atenção!"}</b></span>

      <div>{texto || "Em caso de alteração de alguma informação será necessário enviar uma cópia de um documento com foto e com o CPF."}</div> */}

      <Button
        title={"ANEXAR DOCUMENTOS"}
        className={"m-1"}
        size={"normal"}
        type={"primary"}
        icon={<FaUpload />}
        onClick={clickarInputFile}
      />

      {Loading && <LoadingComponent />}

      <input
        style={{ "opacity": "0" }}
        name={"selecionar-documento"}
        id={"selecionar-documento"}
        type={"file"}
        onChange={uploadFile}
        value={NomeArquivo}
        ref={(ref) => fileUpload = ref}
        accept={"image/tiff, image/jpeg, image/bmp, application/pdf, .png"}
      />

      <br />

      {arquivosEnviados()}

      <br />

      <Alert type={"danger"}>
        {AlertText}
      </Alert>
    </ArquivoUploadBox>
  );
}
import React from 'react';
import { Input, Form, Box } from '@intech/web-ui';

import { DadosAdesaoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
  DataAdmissao: string;
  setDataAdmissao: (d: string) => void;
  form: React.MutableRefObject<Form>;
}

export const DadosFuncionais: React.FC<IProps> = ({
  dados,
  DataAdmissao,
  setDataAdmissao,
  form
}) => {

  return (
    <Box title={"Dados Funcionais"}>
      <Form ref={form}>
        <Input
          labelSize={4}
          title={"Patrocinadora"}
          value={dados.DS_PATROCINADORA}
          readonly
        />

        <Input
          labelSize={4}
          title={"Matrícula Funcional"}
          value={dados.NUM_MATRICULA}
          readonly
        />

        <Input
          labelSize={4}
          title={"Data de Admissão"}
          type={"date"}
          value={DataAdmissao}
          onChange={setDataAdmissao}
          required
        />
      </Form>
    </Box>
  );
}
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Row } from '@intech/web-ui';

import { Header, LoadingComponent } from '@components';
import { AdesaoService } from '@services';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { DadosAdesaoEntidade, SexoEntidade, EstadoCivilEntidade, MunicipioEntidade, UFEntidade, BancoEntidade } from '@entidades';
import { DadosFuncionais } from './DadosFuncionais';
import { DadosPessoais } from './DadosPessoais';
import { DadosEndereco } from './DadosEndereco/Index';
import { DadosBancarios } from './DadosBancarios';

interface IProps {
  dados: DadosAdesaoEntidade;
  setDados: (dados: any) => void;
  Avancar: () => void;
  Voltar: () => void;
}

export const Passo2: React.FC<IProps> = ({
  dados,
  setDados,
  Avancar,
  Voltar
}) => {

  // const form = useRef<Form>(null);
  const DadosFuncionaisForm = useRef<Form>(null);
  const DadosPessoaisForm = useRef<Form>(null);
  const DadosEnderecoForm = useRef<Form>(null);
  const DadosBancariosForm = useRef<Form>(null);

  const [erro, setErro] = useState(null);
  const [Loading, setLoading] = useState<boolean>(true);

  const [DataAdmissao, setDataAdmissao] = useState<string>("");

  const [DataNascimento, setDataNascimento] = useState<string>("");
  const [Sexo, setSexo] = useState<string>("");
  const [DSSexo, setDSSexo] = useState<string>("");
  const [RG, setRG] = useState<string>("");
  const [OrgaoExpedidor, setOrgaoExpedidor] = useState<string>("");
  const [DataExpedicao, setDataExpedicao] = useState<string>("");
  const [EstadoCivil, setEstadoCivil] = useState<string>("");
  const [DSEstadoCivil, setDSEstadoCivil] = useState<string>("");
  const [NomeMae, setNomeMae] = useState<string>("");
  const [NomePai, setNomePai] = useState<string>("");

  const [CEP, setCEP] = useState<string>("");
  const [Endereco, setEndereco] = useState<string>("");
  const [Numero, setNumero] = useState<string>("");
  const [Complemento, setComplemento] = useState<string>("");
  const [Bairro, setBairro] = useState<string>("");
  const [Telefone, setTelefone] = useState<string>("");
  const [Celular, setCelular] = useState<string>("");
  const [Email, setEmail] = useState<string>("");
  const [Municipio, setMunicipio] = useState<string>("");
  const [DSMunicipio, setDSMunicipio] = useState<string>("");
  const [UF, setUF] = useState<string>("");
  const [DSUF, setDSUF] = useState<string>("");

  const [Agencia, setAgencia] = useState<string>("");
  const [Conta, setConta] = useState<string>("");
  const [DVConta, setDVConta] = useState<string>("");
  const [Banco, setBanco] = useState<string>("");
  const [NOBanco, setNOBanco] = useState<string>("");

  const [ListaSexo, setListaSexo] = useState<Array<SexoEntidade>>(new Array<SexoEntidade>());
  const [ListaEstadoCivil, setListaEstadoCivil] = useState<Array<EstadoCivilEntidade>>(new Array<EstadoCivilEntidade>());
  const [ListaMunicipio, setListaMunicipio] = useState<Array<MunicipioEntidade>>(new Array<MunicipioEntidade>());
  const [ListaUF, setListaUF] = useState<Array<UFEntidade>>(new Array<UFEntidade>());
  const [ListaBanco, setListaBanco] = useState<Array<BancoEntidade>>(new Array<BancoEntidade>());

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const ListaSexo = await AdesaoService.BuscarListaSexo();
        setListaSexo(ListaSexo);

        const ListaEstadoCivil = await AdesaoService.BuscarListaEstadoCivil();
        setListaEstadoCivil(ListaEstadoCivil);

        const ListaUF = await AdesaoService.BuscarListaUF();
        setListaUF(ListaUF);

        const ListaBanco = await AdesaoService.BuscarListaBanco();
        setListaBanco(ListaBanco);

        setDataAdmissao(dados.DT_ADMISSAO || "");

        setDataNascimento(dados.DT_NASCIMENTO || "");
        setSexo(dados.IR_SEXO || "");
        setRG(dados.NR_DOC_IDENT || "");
        setOrgaoExpedidor(dados.NO_ORGAO_EXP_IDENT || "");
        setDataExpedicao(dados.DT_EMIS_IDENT || "");
        setEstadoCivil(dados.CD_ESTADO_CIVIL || "");
        setNomeMae(dados.NO_MAE || "");
        setNomePai(dados.NO_PAI || "");

        setCEP(dados.NR_CEP || "");
        setEndereco(dados.DS_ENDERECO || "");
        setNumero(dados.NR_ENDERECO || "");
        setComplemento(dados.DS_COMPLEMENTO || "");
        setBairro(dados.NO_BAIRRO || "");
        setTelefone(dados.NR_FONE || "");
        setCelular(dados.NR_CELULAR || "");
        setEmail(dados.NO_EMAIL || "");
        setMunicipio(dados.CD_MUNICIPIO || "");
        setDSMunicipio(dados.DS_MUNICIPIO || "");
        setUF(dados.CD_UF || "");
        setDSUF(dados.DS_UF || "");

        setAgencia(dados.CD_AGENCIA || "");
        setConta(dados.NR_CC || "");
        setDVConta(dados.DV_CC || "");
        setBanco(dados.CD_BANCO || "");
        setNOBanco(dados.NO_BANCO || "");

        setLoading(false);
      }
      catch (err) {
        const message = err.response ? err.response.data : err;
        setErro(message);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (UF !== "") {
          const ListaMunicipio = await AdesaoService.BuscarListaMunicipioPorUF(UF);
          setListaMunicipio(ListaMunicipio);
        }
      }
      catch (e) {
        setErro(e.response ? e.response.data : e);
      }
    })();
  }, [UF]);

  function SexoOnChange(e: any) {
    const Sexo = ListaSexo.filter(x => x.IR_SEXO === e)[0];

    if (Sexo === undefined) {
      setSexo("");
      setDSSexo("");
      return;
    }

    setSexo(Sexo.IR_SEXO);
    setDSSexo(Sexo.DS_SEXO);
  }

  function EstadoCivilOnChange(e: any) {
    const EstadoCivil = ListaEstadoCivil.filter(x => x.CD_ESTADO_CIVIL.toString() === e)[0];

    if (EstadoCivil === undefined) {
      setEstadoCivil("");
      setDSEstadoCivil("");
      return;
    }

    setEstadoCivil(EstadoCivil.CD_ESTADO_CIVIL.toString());
    setDSEstadoCivil(EstadoCivil.DS_ESTADO_CIVIL);
  }

  function MunicipioOnChange(e: any) {
    const Municipio = ListaMunicipio.filter(x => x.CD_MUNICIPIO === e)[0];

    if (Municipio === undefined) {
      setMunicipio("");
      setDSMunicipio("");
      return;
    }

    setMunicipio(Municipio.CD_MUNICIPIO);
    setDSMunicipio(Municipio.DS_MUNICIPIO);
  }

  function UFOnChange(e: any) {
    const UF = ListaUF.filter(x => x.CD_UF === e)[0];

    if (UF === undefined) {
      setUF("");
      setDSUF("");
      return;
    }

    setUF(UF.CD_UF);
    setDSUF(UF.DS_UF);
  }

  function BancoOnChange(e: any) {
    const Banco = ListaBanco.filter(x => x.CD_BANCO === e)[0];

    if (Banco === undefined) {
      setBanco("");
      setNOBanco("");
      return;
    }

    setBanco(Banco.CD_BANCO);
    setNOBanco(Banco.NO_BANCO);
  }

  function SalvarDados() {
    let Dados = dados;
    Dados.DT_ADMISSAO = DataAdmissao;

    Dados.DT_NASCIMENTO = DataNascimento;
    Dados.IR_SEXO = Sexo;
    Dados.DS_SEXO = DSSexo;
    Dados.NR_DOC_IDENT = RG;
    Dados.NO_ORGAO_EXP_IDENT = OrgaoExpedidor;
    Dados.DT_EMIS_IDENT = DataExpedicao;
    Dados.CD_ESTADO_CIVIL = EstadoCivil;
    Dados.DS_ESTADO_CIVIL = DSEstadoCivil;
    Dados.NO_MAE = NomeMae;
    Dados.NO_PAI = NomePai;

    Dados.NR_CEP = CEP;
    Dados.DS_ENDERECO = Endereco;
    Dados.NR_ENDERECO = Numero;
    Dados.DS_COMPLEMENTO = Complemento;
    Dados.NO_BAIRRO = Bairro;
    Dados.NR_FONE = Telefone;
    Dados.NR_CELULAR = Celular;
    Dados.NO_EMAIL = Email;
    Dados.CD_MUNICIPIO = Municipio;
    Dados.DS_MUNICIPIO = DSMunicipio;
    Dados.CD_UF = UF;
    Dados.DS_UF = DSUF;

    Dados.CD_AGENCIA = Agencia;
    Dados.NR_CC = Conta;
    Dados.DV_CC = DVConta;
    Dados.CD_BANCO = Banco;
    Dados.NO_BANCO = NOBanco;

    setDados(Dados);
  }

  function VoltarPasso() {
    SalvarDados();
    Voltar();
  }

  async function onSubmit() {
    setErro("");

    try {
      const erros = (
        <>
          {await DadosFuncionaisForm?.current?.validate()}
          {await DadosPessoaisForm.current.validate()}
          {await DadosEnderecoForm.current.validate()}
          {await DadosBancariosForm.current.validate()}
          {Celular === "" || Celular.indexOf("_") > -1 ? (<div>{'• Campo "Celular" obrigatório.'}<br /></div>) : null}
        </>
      );

      if (erros.props.children.filter(x => x !== null).length > 0) {
        setErro(erros);
        return;
      }

      await AdesaoService.ValidarEmail(Email);

      await AdesaoService.ValidarDataAdmissao(DataAdmissao.replace(/\//gm, "."), DataNascimento.replace(/\//gm, "."));

      const regex_dv = /\d|x|X/;

      if (DVConta !== "" && !(regex_dv.test(DVConta))) {
        setErro("Campo de DV só aceita números ou o caracter 'X'.");
        return null
      }

      SalvarDados();

      Avancar();
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  return (
    <Header width={"600px"}>
      {Loading && <LoadingComponent />}

      {!Loading && <>
        <b>{`Ótimo, ${dados.NO_PESSOA}!`}</b>
        <br />
        <b>{"Agora precisamos completar seus dados"}</b>
        <br />
        <small>* Obrigatórios</small>

        <br />
        <br />

        <DadosFuncionais
          dados={dados}
          DataAdmissao={DataAdmissao}
          setDataAdmissao={setDataAdmissao}
          form={DadosFuncionaisForm}
        />

        <DadosPessoais
          dados={dados}
          DataNascimento={DataNascimento}
          setDataNascimento={setDataNascimento}
          Sexo={Sexo}
          SexoOnChange={SexoOnChange}
          ListaSexo={ListaSexo}
          RG={RG}
          setRG={setRG}
          OrgaoExpedidor={OrgaoExpedidor}
          setOrgaoExpedidor={setOrgaoExpedidor}
          DataExpedicao={DataExpedicao}
          setDataExpedicao={setDataExpedicao}
          EstadoCivil={EstadoCivil}
          EstadoCivilOnChange={EstadoCivilOnChange}
          ListaEstadoCivil={ListaEstadoCivil}
          NomeMae={NomeMae}
          setNomeMae={setNomeMae}
          NomePai={NomePai}
          setNomePai={setNomePai}
          form={DadosPessoaisForm}
        />

        <DadosEndereco
          CEP={CEP}
          setCEP={setCEP}
          Endereco={Endereco}
          setEndereco={setEndereco}
          Numero={Numero}
          setNumero={setNumero}
          Complemento={Complemento}
          setComplemento={setComplemento}
          Bairro={Bairro}
          setBairro={setBairro}
          Telefone={Telefone}
          setTelefone={setTelefone}
          Celular={Celular}
          setCelular={setCelular}
          Email={Email}
          setEmail={setEmail}
          Municipio={Municipio}
          MunicipioOnChange={MunicipioOnChange}
          ListaMunicipio={ListaMunicipio}
          UF={UF}
          UFOnChange={UFOnChange}
          ListaUF={ListaUF}
          form={DadosEnderecoForm}
        />

        <DadosBancarios
          Agencia={Agencia}
          setAgencia={setAgencia}
          Conta={Conta}
          setConta={setConta}
          DVConta={DVConta}
          setDVConta={setDVConta}
          Banco={Banco}
          BancoOnChange={BancoOnChange}
          ListaBanco={ListaBanco}
          form={DadosBancariosForm}
        />

        <Row>
          <Col>
            <Button
              className={"w-100 float-right"}
              title={"Voltar"}
              type={"secondary"}
              loadingText={"Carregando..."}
              icon={<FaArrowLeft />}
              usesLoading
              block
              submit
              onClick={VoltarPasso}
            />
          </Col>

          <Col>
            <Button
              className={"w-100"}
              title={"Continuar"}
              type={"primary"}
              loadingText={"Carregando..."}
              icon={<FaArrowRight />}
              iconRight
              usesLoading
              block
              submit
              onClick={onSubmit}
            />
          </Col>
        </Row>

        <br />

        <Alert type={"danger"}>{erro}</Alert>

      </>}
    </Header>
  );
}
import styled from "styled-components";

export const Panel = styled.div`
  padding-top: 70px;
  padding-bottom: 50px;
  width: 350px;
  margin: 0 auto;
`;

export const Logo = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
`;

export const PanelTitulo = styled.h4`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;
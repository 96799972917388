import { ComboDataEntidade } from "@src/entidades/ComboDataEntidade";

export const ComboContribuicaoBasica: Array<ComboDataEntidade> = [
  {
    Name: "5%",
    Value: "5"
  },
  {
    Name: "6%",
    Value: "6"
  },
  {
    Name: "7%",
    Value: "7"
  },
  {
    Name: "8%",
    Value: "8"
  },
  {
    Name: "9%",
    Value: "9"
  },
  {
    Name: "10%",
    Value: "10"
  }
];
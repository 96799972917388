import styled from "styled-components";

export const ArquivoUploadBox = styled.div`
  clear: both;
  margin-bottom: 25px;
  padding: 0;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: 15px;
`;

import React from 'react';
import NumberFormat from 'react-number-format';
import { Input, Box, Row, Label, Col } from '@intech/web-ui';

import { DadosAdesaoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
}

export const DadosEndereco: React.FC<IProps> = ({
  dados
}) => {

  return (
    <Box title={"Endereço"}>

      <Input
        title={"CEP"}
        value={dados.NR_CEP}
        mask={"cep"}
        labelSize={6}
        readonly
      />

      <Input
        title={"Logradouro"}
        value={dados.DS_ENDERECO}
        labelSize={6}
        readonly
      />

      <Input
        title={"Número"}
        value={dados.NR_ENDERECO}
        labelSize={6}
        readonly
      />

      <Input
        title={"Complemento"}
        value={dados.DS_COMPLEMENTO}
        labelSize={6}
        readonly
      />

      <Input
        title={"Bairro"}
        value={dados.NO_BAIRRO}
        labelSize={6}
        readonly
      />

      <Input
        value={dados.DS_UF}
        title={"UF"}
        labelSize={6}
        readonly
      />

      <Input
        value={dados.DS_MUNICIPIO}
        title={"Cidade"}
        labelSize={6}
        readonly
      />

      <Input
        value={dados.NR_FONE}
        title={"Telefone Fixo"}
        labelSize={6}
        readonly
      />

      <Input
        value={dados.NR_CELULAR}
        title={"Celular"}
        labelSize={6}
        readonly
      />

      {/* <div className="form-group" >
        <Row>
          <Label
            value={"Telefone Fixo"}
            size={4}
          />

          <Col>
            <div>
              <NumberFormat
                className={"form-control"}
                format={"(##)####-####"}
                mask={"_"}
                value={dados.NR_FONE}
                disabled
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="form-group" >
        <Row>
          <Label
            value={"Celular"}
            size={4}
          />

          <Col>
            <div>
              <NumberFormat
                className={"form-control"}
                format={"(##)#####-####"}
                mask={"_"}
                value={dados.NR_CELULAR}
                disabled
              />
            </div>
          </Col>
        </Row>
      </div> */}

      <Input
        title={"E-mail"}
        value={dados.NO_EMAIL}
        labelSize={6}
        readonly
      />

    </Box>
  );
}
import React from 'react';
import { Input, Form, Box, ComboBox } from '@intech/web-ui';

import { BancoEntidade } from '@entidades';

interface IProps {
  Agencia: string;
  setAgencia: (d: string) => void;
  Conta: string;
  setConta: (d: string) => void;
  DVConta: string;
  setDVConta: (d: string) => void;
  Banco: string;
  BancoOnChange: (e: any) => void;
  ListaBanco: Array<BancoEntidade>;
  form: React.MutableRefObject<Form>;
}

export const DadosBancarios: React.FC<IProps> = ({
  Agencia,
  setAgencia,
  Conta,
  setConta,
  DVConta,
  setDVConta,
  Banco,
  BancoOnChange,
  ListaBanco,
  form
}) => {

  return (
    <Box title={"Dados Bancários"}>
      <Form ref={form}>
        <ComboBox
          value={Banco}
          title={"Banco"}
          data={ListaBanco}
          onChange={BancoOnChange}
          memberName={"NO_BANCO_COMPLETO"}
          memberValue={"CD_BANCO"}
          emptyText={"Escolha seu Banco"}
          labelSize={4}
          required
        />

        <Input
          labelSize={4}
          title={"Agência"}
          value={Agencia}
          onChange={setAgencia}
          max={5}
          required
        />

        <Input
          labelSize={4}
          title={"N° da Conta"}
          value={Conta}
          onChange={setConta}
          max={12}
          required
        />

        <Input
          labelSize={4}
          title={"DV da Conta"}
          value={DVConta}
          onChange={setDVConta}
          max={1}
          required
        />
      </Form>
    </Box>
  );
}
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Alert, Box, Button, ButtonSwitch, Col, ComboBox, Form, Input, Label, Row } from '@intech/web-ui';

import { Header, ListaDependente, LoadingComponent } from '@components';

import { FaArrowLeft, FaArrowRight, FaCheck, FaEdit, FaTimes } from 'react-icons/fa';
import { DadosAdesaoEntidade, DependenteEntidade, GrauParentescoEntidade, SexoEntidade } from '@entidades';
import { Passos } from '../Handler';
import { AdesaoService } from '@services';
import { ArquivoUpload, ArquivoUploadItem } from '@src/components/ArquivoUpload';

interface IProps {
  dados: DadosAdesaoEntidade;
  setDados: (dados: any) => void;
  Avancar: (PassoEspecifico?: Passos | null) => void;
  Voltar: () => void;
}

export const Passo5: React.FC<IProps> = ({
  dados,
  setDados,
  Avancar,
  Voltar
}) => {

  const form = useRef<Form>(null);

  const [ListaArquivo, setListaArquivo] = useState<Array<ArquivoUploadItem>>(new Array<ArquivoUploadItem>());

  const [erro, setErro] = useState(null);
  const [Loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        setListaArquivo(dados.ListaArquivo || new Array<ArquivoUploadItem>());

        setLoading(false);
      }
      catch (err) {
        const message = err.response ? err.response.data : err;
        setErro(message);
        setLoading(false);
      }
    })();
  }, []);

  function SalvarDados() {
    let Dados = dados;

    Dados.ListaArquivo = ListaArquivo;

    setDados(Dados);
  }

  function VoltarPasso() {
    SalvarDados();
    Voltar();
  }

  async function onSubmit() {
    setErro("");

    try {
      if (ListaArquivo.length === 0) {
        setErro("O envio de pelo menos um documento é obrigatório.");
        return;
      }

      SalvarDados();

      Avancar();
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  return (
    <Header width={"700px"}>
      {Loading && <LoadingComponent />}

      {!Loading && <>

        <b>{"Documentos"}</b>
        <br />

        <Alert type={"info"}>
          <p>
            <b>
              {"Você deverá anexar os documentos comprobatórios conforme abaixo:"}
            </b>
          </p>

          <p>
            <b>
              {"Documentos do titular:"}
            </b>
            {" RG, CPF, comprovante de conta bancária, certidão de casamento ou escritura pública de união estável."}
          </p>

          <p>
            <b>
              {"Documentos dos dependentes:"}
            </b>
            {" RG, CPF ou certidão de nascimento."}
          </p>
        </Alert>

        <ArquivoUpload
          PassoAtual={"passo5"}
          ListaArquivo={ListaArquivo}
          setListaArquivo={setListaArquivo}
        />

        <Alert type={"danger"}>{erro}</Alert>

        <Row>
          <Col>
            <Button
              className={"w-100 float-right"}
              title={"Voltar"}
              type={"secondary"}
              loadingText={"Carregando..."}
              icon={<FaArrowLeft />}
              usesLoading
              block
              onClick={VoltarPasso}
            />
          </Col>

          <Col>
            <Button
              className={"w-100"}
              title={"Continuar"}
              type={"primary"}
              loadingText={"Carregando..."}
              icon={<FaArrowRight />}
              iconRight
              usesLoading
              block
              onClick={onSubmit}
              disabled={ListaArquivo.length === 0}
            />
          </Col>
        </Row>

      </>}
    </Header>
  );
}
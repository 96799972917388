import React from "react";
import { RouteProps } from "react-router-dom";
import { WebRoute, WebArea } from "@intech/web-ui";

import {
  Handler
} from "../pages";

export const routes: Array<WebRoute | WebArea> = [
  new WebRoute({
    id: "inicio",
    path: "/",
    title: "Inicio",
    component: (routeProps: RouteProps) => <Handler {...routeProps} />,
    exactPath: true,
    showInMenu: true,
  }),
];

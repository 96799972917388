import React from 'react';
import { Box, Button, ButtonSwitch, Col, Row, useModal } from '@intech/web-ui';

import { USPersonModalConteudo } from './USPersonModalConteudo';

interface IProps {
  USP: string;
  setUSP: (e: any) => void;
}

export const USPerson: React.FC<IProps> = ({
  USP,
  setUSP
}) => {
  const modal = useModal();

  function OpenModal() {
    modal.openModal({
      title: "Você é um US PERSON?",
      type: "body",
      content: <USPersonModalConteudo />,
    })
  };

  return (
    <Box title={"US PERSON"} badgeText={<Button title={"(Saiba Mais)"} type={"link"} onClick={OpenModal} />}>

      <Row className={"mb-1"}>
        <Col className={"text-left"}>
          <b>
            <span className={"text-danger"}>* </span>
            {"Considera-se, para os devidos fins de direito como US PERSON?"}
          </b>
        </Col>
      </Row>

      <Row className={"mb-1"}>
        <Col className={"d-flex justify-content-end"}>
          <ButtonSwitch
            activeType={"success"}
            inactiveType={"light"}
            inactiveIcon={" "}
            activeTitle={"SIM"}
            inactiveTitle={"SIM"}
            checked={USP === "SIM"}
            onChange={() => setUSP("SIM")}
          />
        </Col>
        <Col>
          <ButtonSwitch
            activeType={"success"}
            inactiveType={"light"}
            inactiveIcon={" "}
            activeTitle={"NAO"}
            inactiveTitle={"NAO"}
            checked={USP === "NAO"}
            onChange={() => setUSP("NAO")}
          />
        </Col>
      </Row>
    </Box>
  );
}
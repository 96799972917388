import React from 'react';
import { Input, Box } from '@intech/web-ui';

import { DadosAdesaoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
}

export const DadosFuncionais: React.FC<IProps> = ({
  dados
}) => {

  return (
    <Box title={"Dados Funcionais"}>
      <Input
        labelSize={6}
        title={"Empresa Patrocinadora"}
        value={dados.DS_PATROCINADORA}
        readonly
      />

      <Input
        labelSize={6}
        title={"Matrícula Funcional"}
        value={dados.NUM_MATRICULA}
        readonly
      />

      <Input
        labelSize={6}
        title={"Data de Admissão"}
        type={"date"}
        value={dados.DT_ADMISSAO}
        readonly
      />
    </Box>
  );
}
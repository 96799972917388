import React from 'react';
import { Input, Form, Box, ComboBox } from '@intech/web-ui';

import { DadosAdesaoEntidade, EstadoCivilEntidade, SexoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
  DataNascimento: string;
  setDataNascimento: (d: string) => void;
  Sexo: string;
  SexoOnChange: (e: any) => void;
  ListaSexo: Array<SexoEntidade>;
  RG: string;
  setRG: (d: string) => void;
  OrgaoExpedidor: string;
  setOrgaoExpedidor: (d: string) => void;
  DataExpedicao: string;
  setDataExpedicao: (d: string) => void;
  EstadoCivil: string;
  EstadoCivilOnChange: (e: any) => void;
  ListaEstadoCivil: Array<EstadoCivilEntidade>;
  NomeMae: string;
  setNomeMae: (d: string) => void;
  NomePai: string;
  setNomePai: (d: string) => void;
  form: React.MutableRefObject<Form>;
}

export const DadosPessoais: React.FC<IProps> = ({
  dados,
  DataNascimento,
  setDataNascimento,
  Sexo,
  SexoOnChange,
  ListaSexo,
  RG,
  setRG,
  OrgaoExpedidor,
  setOrgaoExpedidor,
  DataExpedicao,
  setDataExpedicao,
  EstadoCivil,
  EstadoCivilOnChange,
  ListaEstadoCivil,
  NomeMae,
  setNomeMae,
  NomePai,
  setNomePai,
  form
}) => {

  return (
    <Box title={"Dados Pessoais"}>
      <Form ref={form}>
        <Input
          labelSize={4}
          title={"Nome Completo"}
          value={dados.NO_PESSOA}
          readonly
        />

        <Input
          labelSize={4}
          title={"Data de Nascimento"}
          type={"date"}
          value={DataNascimento}
          onChange={setDataNascimento}
          required
        />

        <ComboBox
          value={Sexo}
          title={"Sexo"}
          data={ListaSexo}
          onChange={SexoOnChange}
          memberName={"DS_SEXO"}
          memberValue={"IR_SEXO"}
          emptyText={"Escolha seu Sexo"}
          labelSize={4}
          required
        />

        <Input
          labelSize={4}
          title={"CPF"}
          mask={"cpf"}
          value={dados.CPF_CGC}
          readonly
        />

        <Input
          labelSize={4}
          title={"RG"}
          value={RG}
          onChange={setRG}
          max={20}
        />

        <Input
          labelSize={4}
          title={"Órgão Expedidor / Estado"}
          value={OrgaoExpedidor}
          onChange={setOrgaoExpedidor}
          max={20}
        />

        <Input
          labelSize={4}
          title={"Data de Expedição"}
          type={"date"}
          value={DataExpedicao}
          onChange={setDataExpedicao}
        />

        <ComboBox
          value={EstadoCivil}
          title={"Estado Civil"}
          data={ListaEstadoCivil}
          onChange={EstadoCivilOnChange}
          memberName={"DS_ESTADO_CIVIL"}
          memberValue={"CD_ESTADO_CIVIL"}
          emptyText={"Escolha seu EstadoCivil"}
          labelSize={4}
          required
        />

        <Input
          labelSize={4}
          title={"Nome da Mãe"}
          value={NomeMae}
          onChange={setNomeMae}
          max={140}
          required
        />

        <Input
          labelSize={4}
          title={"Nome do Pai"}
          value={NomePai}
          onChange={setNomePai}
          max={140}
        />

      </Form>
    </Box>
  );
}
const defaultTheme = {
  name: 'DEFAULT',
  colors: {
    primary: '#008f4c',
    secondary: '#1a7bb9',

    white: '#FFF',
    gray: '#8B8B8B',

    green: '#10cfbd',
    orange: '#E89E64',
    pink: '#FF79C6',
    cyan: '#78D1E1',
    red: '#f55753',
    blue: '#48b0f7',
    yellow: '#f8d053',

    pageHeaderText: '#FFFFFF'
  },
  backgrounds: {
    nav: '#FFF',
    pageContent: '#f3f3f4',
    pageHeader: '#008f4c'
  },
  sizes: {
    navbar: '275px',
    navMenuItem: '17px'
  }
}

export default defaultTheme;

import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.min.css";

import { WebRouter, WebUiProvider } from "@intech/web-ui";

import "./styles/variables.scss";
import theme from "./styles/theme";

import { routes } from "./routes";

console.log(routes);

ReactDOM.render(
  <WebUiProvider theme={theme}>
    <WebRouter hashRouter rotas={routes} />
  </WebUiProvider>,
  document.getElementById("root")
);

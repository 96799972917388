import React, { useEffect, useRef, useState } from 'react';
import { Input, Alert, Button, ComboBox, Form, Row, Col, ButtonSwitch } from '@intech/web-ui';
import { Session } from "@intech/react-service";

import { Header, ListaDependente, LoadingComponent } from '@components';
import { AdesaoService } from '@services';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { PatrocinadoraEntidade, DadosAdesaoEntidade, DependenteEntidade } from '@entidades';
import { Passos } from '../Handler';
import { DadosFuncionais } from './DadosFuncionais';
import { DadosEndereco } from './DadosEndereco';
import { DadosPessoais } from './DadosPessoais';
import { DadosBancarios } from './DadosBancarios';
import { OutrasInformacoes } from './OutrasInformacoes';

interface IProps {
  dados: DadosAdesaoEntidade;
  Voltar: () => void;
}

export const Resumo: React.FC<IProps> = ({
  dados,
  Voltar
}) => {

  const form = useRef<Form>(null);

  const [TipoDependente, setTipoDependente] = useState<string>("NAO");
  const [AceitaTermos, setAceitaTermos] = useState<boolean>(false);
  const [erro, setErro] = useState(null);
  const [Loading, setLoading] = useState<boolean>(true);
  const [MsgFinal, setMsgFinal] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (dados.ListaDependentes && dados.ListaDependentes.length > 0) {

          setTipoDependente(dados.ListaDependentes[0].IND_DESIGNADO);
        }
        setLoading(false);
      }
      catch (err) {
        const message = err.response ? err.response.data : err;
        setErro(message);
        setLoading(false);
      }
    })();
  }, []);

  function VoltarPasso() {
    Voltar();
  }

  async function onSubmit() {
    setErro("");

    try {
      if (!AceitaTermos) {
        setErro("É necessario manifestar ciência e plena concordância com as informações aqui registradas para prosseguir com a Proposta de Inscrição.");
      }

      setMsgFinal(await AdesaoService.Concluir(dados));
    }
    catch (err) {
      const message = err.response ? err.response.data : err;
      setErro(message);
    }
  }

  return (
    <Header width={"800px"}>
      {Loading && <LoadingComponent />}

      {!Loading && <>
        <b>{"Resumo das informações"}</b>

        <br />

        <DadosFuncionais dados={dados} />

        <DadosPessoais dados={dados} />

        <DadosEndereco dados={dados} />

        <DadosBancarios dados={dados} />

        <OutrasInformacoes dados={dados} />

        <ListaDependente
          ListaDependentes={dados.ListaDependentes || new Array<DependenteEntidade>()}
          editar={null}
          remover={null}
          title={TipoDependente === "SIM" ? "Designados" : "Beneficiários"}
        />

        <Alert type={"warning"}>
          <Row>
            <Col className={"col-lg-1"}>
              <ButtonSwitch
                activeType={"success"}
                inactiveType={"light"}
                inactiveIcon={" "}
                checked={AceitaTermos}
                onChange={() => setAceitaTermos(!AceitaTermos)}
              />
            </Col>
            <Col>
              {"Manifesto ciência e plena concordância com as informações aqui registradas, atestando a veracidade das mesmas."}
            </Col>
          </Row>
        </Alert>

        {MsgFinal === "" && <>
          <Row>
            <Col>
              <Button
                className={"w-100 float-right"}
                title={"Voltar"}
                type={"secondary"}
                loadingText={"Carregando..."}
                icon={<FaArrowLeft />}
                usesLoading
                block
                onClick={VoltarPasso}
              />
            </Col>

            <Col>
              <Button
                className={"w-100"}
                title={"Confirmar Proposta de Inscrição"}
                type={"primary"}
                loadingText={"Confirmando..."}
                icon={<FaArrowRight />}
                iconRight
                usesLoading
                block
                onClick={onSubmit}
                disabled={!AceitaTermos}
              />
            </Col>
          </Row>
        </>}

        {MsgFinal !== "" &&
          <Alert type={"info"}>
            {MsgFinal}
            <br />
            <Button title={"OK"} onClick={() => window.open("https://www.faceb.com.br/", "_self")} type={"secondary"} size={"mini"} />
          </Alert>
        }

        <br />

        <Alert type={"danger"}>{erro}</Alert>
      </>}
    </Header>
  );
}
import React from 'react';
import { Input, Form, Box, ComboBox } from '@intech/web-ui';

import { DadosAdesaoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
}

export const DadosPessoais: React.FC<IProps> = ({
  dados
}) => {

  return (
    <Box title={"Dados Pessoais"}>
      <Input
        labelSize={6}
        title={"Nome Completo"}
        value={dados.NO_PESSOA}
        readonly
      />

      <Input
        labelSize={6}
        title={"Data de Nascimento"}
        type={"date"}
        value={dados.DT_NASCIMENTO}
        readonly
      />

      <Input
        value={dados.DS_SEXO}
        title={"Sexo"}
        labelSize={6}
        readonly
      />

      <Input
        labelSize={6}
        title={"CPF"}
        mask={"cpf"}
        value={dados.CPF_CGC}
        readonly
      />

      <Input
        labelSize={6}
        title={"RG"}
        value={dados.NR_DOC_IDENT}
        readonly
      />

      <Input
        labelSize={6}
        title={"Órgão Expedidor"}
        value={dados.NO_ORGAO_EXP_IDENT}
        readonly
      />

      <Input
        labelSize={6}
        title={"Data de Expedição"}
        type={"date"}
        value={dados.DT_EMIS_IDENT}
        readonly
      />

      <Input
        value={dados.DS_ESTADO_CIVIL}
        title={"Estado Civil"}
        labelSize={6}
        readonly
      />

      <Input
        labelSize={6}
        title={"Nome da Mãe"}
        value={dados.NO_MAE}
        readonly
      />

      <Input
        labelSize={6}
        title={"Nome do Pai"}
        value={dados.NO_PAI}
        readonly
      />

    </Box>
  );
}
import React from 'react';
import { Input, Box } from '@intech/web-ui';

import { DadosAdesaoEntidade } from '@entidades';

interface IProps {
  dados: DadosAdesaoEntidade;
}

export const OutrasInformacoes: React.FC<IProps> = ({
  dados
}) => {

  return (
    <Box title={"Outras Informações"}>
      <Input
        labelSize={6}
        title={"Percentual de Contribuição"}
        value={dados.CONTRIB_BASICA}
        readonly
      />

      {/* <Input
        labelSize={6}
        title={"Regime de Tributação"}
        value={dados.IRRF_OPCAO_TRIBUTACAO}
        readonly
      /> */}

      <Input
        labelSize={6}
        title={"Data de Admissão"}
        value={dados.DT_ADMISSAO}
        readonly
      />

      <Input
        labelSize={6}
        title={"Pessoa Politicamente Exposta (PPE)"}
        value={dados.PPE}
        readonly
      />

      <Input
        labelSize={6}
        title={"Pessoa Politicamente Exposta na Família"}
        value={dados.FPE}
        readonly
      />

      <Input
        labelSize={6}
        title={"FACTA (US PERSON)"}
        value={dados.USP}
        readonly
      />
    </Box>
  );
}
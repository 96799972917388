import React from 'react';

import { Header } from '@components';
import { Box, Button } from '@intech/web-ui';
import { DadosAdesaoEntidade } from '@entidades';
import { CenterLine } from './styles';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import { Passos } from '../Handler';

interface IProps {
  dados: DadosAdesaoEntidade;
  Voltar: (PassoEspecifico?: Passos | null) => void;
}

export const Cadastrado: React.FC<IProps> = ({
  dados,
  Voltar
}) => {

  return (
    <Header>
      <Box>
        <CenterLine>{`Olá, ${dados.NO_PESSOA}`}</CenterLine>

        <br />

        <CenterLine>{"Você já é participante da FACEB!"}</CenterLine>

        <br />

        <CenterLine>{"PLANO CEBPREV"}</CenterLine>

        <CenterLine>{`Data de Inscrição: ${moment(dados.DT_INSCRICAO, "DD/MM/YYYY").format("DD/MM/YYYY")}`}</CenterLine>

        <CenterLine>{`Situação: ${dados.DS_SIT_PLANO}`}</CenterLine>

        <br />

        <Button
          title={"Voltar"}
          type={"secondary"}
          loadingText={"Carregando..."}
          icon={<FaArrowLeft />}
          usesLoading
          block
          submit
          onClick={() => Voltar(Passos.Passo1)}
        />
      </Box>
    </Header>
  )
}